







































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  BBadge,
  BLink,
  BFormInput,
  BPagination,
  BTable,
  BCard,
  BSpinner,
  BMedia,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BRow,
  BCol,
  BButton,
  BSidebar,
  VBToggle
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { defaultPagination, Pagination } from '@/models/General'
import { Action } from '@/store/decorators'
import { ProviderModule } from '@/store/modules/providerModule'
import { avatarText } from '@core/utils/filter'

import {
  defaultParamsPayload,
  ModelPayload,
  ParamsPayload
} from '@/models/Payloads'
import AdminAddUserSidebar from '@/views/admin/components/AdminAddUserSidebar.vue'
import AdminUpdateUserSidebar from '@/views/admin/components/AdminUpdateUserSidebar.vue'
import { UserRoleEnums } from '@/enums/User'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mixins } from 'vue-class-component'
import MixinFunctions from '@/mixins/mixinFunctions'

@Component({
  name: 'AdminProvidersList',
  components: {
    BBadge,
    BLink,
    BFormInput,
    BPagination,
    BTable,
    BCard,
    BSpinner,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BRow,
    BCol,
    BButton,
    BSidebar,
    VSelect,
    AdminAddUserSidebar,
    AdminUpdateUserSidebar
  },
  directives: {
    'b-toggle': VBToggle
  }
})
export default class AdminProvidersList extends mixins(Vue, MixinFunctions) {
  @Action(ProviderModule, 'fetchAdminProviderUsers') private fetchAdminProviderUsers!: (payload: ModelPayload) => Promise<Pagination>

  $refs!: { refProviderUsersTable }

  private fields: object = [
    { key: 'user' },
    { key: 'email' },
    { key: 'roles' },
    { key: 'account_status', label: 'Status' },
    { key: 'actions', thStyle: { width: '1%' } }
  ]

  private userRole: string = UserRoleEnums.Provider
  private providerPagination: Pagination = defaultPagination()
  private avatarText: object = avatarText
  private paramsPayload: ParamsPayload = defaultParamsPayload()
  private perPageOptions: number[] = [10, 25, 50, 100]
  private totalData: number = 0
  private searchQuery: string = ''
  private searchQueryTimeout: any = null

  private created () {
    this.paramsPayload = this.loadQueryString(this.paramsPayload)
  }

  private async loadProviders () {
    try {
      this.providerPagination = await this.fetchAdminProviderUsers({
        params: this.paramsPayload
      })

      this.totalData = this.providerPagination.total

      return this.providerPagination.data
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: e.message,
          icon: 'AlertTriangleIcon',
          variant: 'danger'
        }
      })
      // console.error(error)
    }
  }

  private userData: object = {};
  private passProviderData (userData) {
    // console.log(userData)
    this.userData = userData
  }

  get dataMeta () {
    const localItemsCount = this.$refs.refProviderUsersTable ? this.$refs.refProviderUsersTable.localItems.length : 0

    return {
      from: this.paramsPayload.perPage * (this.paramsPayload.page - 1) + (localItemsCount ? 1 : 0),
      to: this.paramsPayload.perPage * (this.paramsPayload.page - 1) + localItemsCount,
      of: this.totalData
    }
  }

  @Watch('paramsPayload', { deep: true, immediate: false })
  private refreshTable () {
    clearTimeout(this.searchQueryTimeout)
    this.$refs.refProviderUsersTable.refresh()
  }

  @Watch('searchQuery')
  private onSearchQuery () {
    this.paramsPayload.q = this.searchQuery
    clearTimeout(this.searchQueryTimeout)

    this.searchQueryTimeout = setTimeout(() => {
      this.refreshTable()
    }, 1000)
  }
}
